let allPeople = [
	{
		"`": "8/2/2022 23:29:03",
		"Email Address": "20107@iiitu.ac.in",
		Name: "Rishabh Kumar",
		"Roll No.": 20107,
		"Personal Email": "akshatmittal2506@gmail.com",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
			"Tech-Team Member",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"Skilled in React, Node.js, Flask, and Python, experienced in full-stack development and API creation for scalable solutions.",
		"Instagram username": "Nil",
		"LinkedIn Username": "https://www.linkedin.com/in/rishabh-kumar-438799293/",
		"Twitter Username": "https://twitter.com/0xR111",
		"Phone No.": 8789944397,
		"Profile Photo (preferably focused on face)":
			"Rishabh.jpg",
	},
	{
		"`": "8/2/2022 23:33:39",
		"Email Address": "21235@iiitu.ac.in",
		Name: "Ananya V",
		"Roll No.": 21235,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Media Team Member",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"I am flexible and easy going and want to grab on the best opportunities that come in my way.",
		"Instagram username": "nil",
		"LinkedIn Username":
			"nil",
		"Twitter Username":
			"nil",
		"Phone No.": 90,
		"Profile Photo (preferably focused on face)":
			"Ana.jpg",
	},
	{
		"`": "8/2/2022 23:33:39",
		"Email Address": "22235@iiitu.ac.in",
		Name: "Aparna A",
		"Roll No.": 21235,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Design Team Member",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"I am a part of design team in software development club. I do design posters , logos ,postcards and I use Adobe illustrator, canva and figma.",
		"Instagram username": "nil",
		"LinkedIn Username":
			"nil",
		"Twitter Username":
			"nil",
		"Phone No.": 9148555573,
		"Profile Photo (preferably focused on face)":
			"Aparna.jpg",
	},
	{
		"`": "8/2/2022 23:33:39",
		"Email Address": "22235@iiitu.ac.in",
		Name: "Aishwarya Singh",
		"Roll No.": 21235,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Design Team Member",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"Proficient in Java development ,Graphic designer and a Tech Enthusiast",
		"Instagram username": "ashhhh411",
		"LinkedIn Username":
			"https://www.linkedin.com/in/aishwarya-singh-51551a255",
		"Twitter Username":
			"nil",
		"Phone No.": 9686115909,
		"Profile Photo (preferably focused on face)":
			"Singh.jpg",
	},
	{
		"`": "8/2/2022 23:33:39",
		"Email Address": "22235@iiitu.ac.in",
		Name: "Purushotham B M",
		"Roll No.": 21235,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Design Team Member",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"I am frontend developer,skilled in Reactjs, Javascript, tailwindCss, html ",
		"Instagram username": "https://www.instagram.com/the_purush?igsh=YXJ1YmllZGhhc3o4",
		"LinkedIn Username":
			"https://www.linkedin.com/in/purushotham-bm-63543822a",
		"Twitter Username":
			"https://x.com/Purushotham_007?s=09",
		"Phone No.": 7204144705,
		"Profile Photo (preferably focused on face)":
			"purush.jpg",
	},
	{
		"`": "8/2/2022 23:33:39",
		"Email Address": "22235@iiitu.ac.in",
		Name: "MegahaShree V",
		"Roll No.": 21235,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Design Team Member",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"Software developer ,Good at designing with figma",
		"Instagram username": "https://www.instagram.com/meghaaa.v?utm_source=qr&igsh=NmQyc2IxZnVseXEz",
		"LinkedIn Username":
			"https://www.linkedin.com/in/meghashree-v-6b4256258?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
		"Twitter Username":
			"nil",
		"Phone No.": 6364754673,
		"Profile Photo (preferably focused on face)":
			"Megashree.jpg",
	},
	{
		"`": "8/2/2022 23:33:39",
		"Email Address": "22235@iiitu.ac.in",
		Name: "Divya Varshini",
		"Roll No.": 21235,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Design Team Memeber",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"Basics of flutter app development Java,c,c++ basics ",
		"Instagram username": "Itz_dv_2k3 ",
		"LinkedIn Username":
			"https://www.linkedin.com/in/d%F0%9D%92%8A%F0%9D%92%97%F0%9D%92%9A%F0%9D%92%82%F0%9D%92%82-%F0%9D%91%BD%F0%9D%92%82%F0%9D%92%93%F0%9D%92%94%F0%9D%92%89%F0%9D%92%8A%F0%9D%92%8F%F0%9D%92%8A-ab8606252?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
		"Twitter Username":
			"nil",
		"Phone No.": 7483466469,
		"Profile Photo (preferably focused on face)":
			"Divya.jpg",
	},
	{
		"`": "8/2/2022 23:33:39",
		"Email Address": "22235@iiitu.ac.in",
		Name: "Anindhaya Sinha",
		"Roll No.": 21235,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Design Team Member",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"Programming ",
		"Instagram username": "anindhaya_____",
		"LinkedIn Username":
			"Anindhaya Sinha",
		"Twitter Username":
			"Anindhaya_____",
		"Phone No.": 7058961830,
		"Profile Photo (preferably focused on face)":
			"Sinha.jpg",
	},
	{
		"`": "8/2/2022 23:33:39",
		"Email Address": "22235@iiitu.ac.in",
		Name: "Shravan",
		"Roll No.": 21235,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Design Team Member",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"I am a 3rd sem data science student Mostly interested in design and graphics ",
		"Instagram username": "Sravankumar1102",
		"LinkedIn Username":
			"nil",
		"Twitter Username":
			"nil",
		"Phone No.": 7204700369 ,
		"Profile Photo (preferably focused on face)":
			"sravan.jpg",
	},
	{
		"`": "8/2/2022 23:33:39",
		"Email Address": "22235@iiitu.ac.in",
		Name: "Vatsala Budur",
		"Roll No.": 21235,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Design Team Member",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"Enthusiastic about Machine Learning and Deep Learning technologies, constantly exploring and experimenting with new concepts and techniques. ",
		"Instagram username": "nil",
		"LinkedIn Username":
			"https://www.linkedin.com/in/vatsala-budur-83ab25259/",
		"Twitter Username":
			"nil",
		"Phone No.": 9611266007,
		"Profile Photo (preferably focused on face)":
			"Vatsala.jpg",
	},
	{
		"`": "8/2/2022 23:33:39",
		"Email Address": "22235@iiitu.ac.in",
		Name: "Rohith Lokesh",
		"Roll No.": 21235,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Design Team Member",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"Html,Css,Java,Javascript,Python Poster and Brochure Designing Using Canva,FigmaBasic idea on Excel,SQL,Tableau,Data Visualisation",
		"Instagram username": "@rohith.lokesh.2912",
		"LinkedIn Username":
			"https://www.linkedin.com/in/rohith-lokesh-27581824b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
		"Twitter Username":
			"@rohithl2912",
		"Phone No.": 9632911306,
		"Profile Photo (preferably focused on face)":
			"Rohith.jpg",
	},
  //Content Team

  {
	"`": "8/2/2022 23:33:39",
	"Email Address": "23235@iiitu.ac.in",
	Name: "Adithi Shibu",
	"Roll No.": 21235,
	"Personal Email": "nil",
	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Content Team Member",
	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
		"Proficient in python development, content writer and tech enthusiast ",
	"Instagram username": "nil",
	"LinkedIn Username":
		"https://www.linkedin.com/in/adithi-shibu-0a49a3265?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
	"Twitter Username":
		"nil",
	"Phone No.": 6364562782,
	"Profile Photo (preferably focused on face)":
		"aditi.jpg",
},
  {
	"`": "8/2/2022 23:33:39",
	"Email Address": "23235@iiitu.ac.in",
	Name: "Sanjana",
	"Roll No.": 21235,
	"Personal Email": "nil",
	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Content Team Member",
	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
		"I am an avid learner, always on the hunt for new experiences",
	"Instagram username": "https://www.instagram.com/sanjana_balasubramanya?igsh=bHV1dmhvb3dqamNu",
	"LinkedIn Username":
		"https://www.linkedin.com/in/sanjana-balasubramanya-b72b25268?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
	"Twitter Username":
		"nil",
	"Phone No.": 7892742807,
	"Profile Photo (preferably focused on face)":
		"sanja.jpg",
},
  {
	"`": "8/2/2022 23:33:39",
	"Email Address": "23235@iiitu.ac.in",
	Name: "Chandana R",
	"Roll No.": 21235,
	"Personal Email": "nil",
	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Content Team Member",
	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
		"Basics of C,C++,Java, python ",
	"Instagram username": "https://www.instagram.com/_chandanagowda_23?igsh=dHBrODYxZmlnMjQ3",
	"LinkedIn Username":
		"https://www.linkedin.com/in/chandana-r-gowda-68586725b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
	"Twitter Username":
		"https://x.com/Chandanagowda05?t=QktMlRU9rKz4SLw2ZNUMfg&s=08",
	"Phone No.": 8904739464,
	"Profile Photo (preferably focused on face)":
		"Chandana.jpg",
},
  {
	"`": "8/2/2022 23:33:39",
	"Email Address": "23235@iiitu.ac.in",
	Name: "Thejaswini M",
	"Roll No.": 21235,
	"Personal Email": "nil",
	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Content Team Member",
	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
		" Graphic design and web development enthusiast",
	"Instagram username": "__tejzzz29_",
	"LinkedIn Username":
		"http://www.linkedin.com/in/thejaswini-m",
	"Twitter Username":
		"nil",
	"Phone No.": 9901212286,
	"Profile Photo (preferably focused on face)":
		"Thejasw.jpg",
},
  {
	"`": "8/2/2022 23:33:39",
	"Email Address": "23235@iiitu.ac.in",
	Name: "Prem",
	"Roll No.": 21235,
	"Personal Email": "nil",
	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Content Team Member",
	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
		"Hooked onto cloud hosting, still figuring out why.",
	"Instagram username": "premreddy._",
	"LinkedIn Username":
		"https://www.linkedin.com/in/prem-reddy-59a608245",
	"Twitter Username":
		"notkneesocks ",
	"Phone No.": 9642985537,
	"Profile Photo (preferably focused on face)":
		"Prem.jpg",
},
  {
	"`": "8/2/2022 23:33:39",
	"Email Address": "23235@iiitu.ac.in",
	Name: "Shreenath S",
	"Roll No.": 21235,
	"Personal Email": "nil",
	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Content Team Member",
	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
		"Aspiring data scientist and a content writer . Highly Passionate about learning new things , and be a better version of myself.",
	"Instagram username": "shreenath_hebbar",
	"LinkedIn Username":
		"https://www.linkedin.com/in/shreenath-s-hebbar-370376223",
	"Twitter Username":
		"nil",
	"Phone No.": 6362835068,
	"Profile Photo (preferably focused on face)":
		"Shreenath.jpg",
},

//social media team
{
	"`": "8/2/2022 23:33:39",
	"Email Address": "24235@iiitu.ac.in",
	Name: "Adrija Das Gupta",
	"Roll No.": 21235,
	"Personal Email": "nil",
	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Social Media Member",
	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
		"Aspiring Full Stack Developer |  HTML | CSS |  JavaScript | Python | Java |  C/C++ | Seeking opportunities to innovate and create impactful web solutions",
	"Instagram username": "_._adrijaaa_._",
	"LinkedIn Username":
		"https://www.linkedin.com/in/Adrija Das Gupta ",
	"Twitter Username":
		"nil",
	"Phone No.": 7899307983,
	"Profile Photo (preferably focused on face)":
		"Das.jpeg",
},
{
	"`": "8/2/2022 23:33:39",
	"Email Address": "24235@iiitu.ac.in",
	Name: "K Divit",
	"Roll No.": 21235,
	"Personal Email": "nil",
	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Social Media Member",
	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
		"Java,CSS,HTML,Python,C++",
	"Instagram username": "https://www.instagram.com/i_am_divith?igsh=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr",
	"LinkedIn Username":
		"https://www.linkedin.com/in/divith-kurupati-45093b231?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
	"Twitter Username":
		"https://x.com/DivithKurupati?t=AyigH-ztZKz48oI00qQiWA&s=09",
	"Phone No.": 9611552392,
	"Profile Photo (preferably focused on face)":
		"divit.jpg",
},
{
	"`": "8/2/2022 23:33:39",
	"Email Address": "24235@iiitu.ac.in",
	Name: "Yuvraj V",
	"Roll No.": 21235,
	"Personal Email": "nil",
	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Social Media Member",
	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
		"Programming languages I know are C and C+ , basics of html and python  ",
	"Instagram username": "https://www.instagram.com/yuvrajv__?igsh=MWp4NjhsYXdsemo0&utm_source=qr",
	"LinkedIn Username":
		"https://www.linkedin.com/in/yuvraj-v-2401b9292",
	"Twitter Username":
		"nil",
	"Phone No.": 8971728640,
	"Profile Photo (preferably focused on face)":
		"yuvi.jpg",
},
{
	"`": "8/2/2022 23:33:39",
	"Email Address": "24235@iiitu.ac.in",
	Name: "Vishwas Kumar",
	"Roll No.": 21235,
	"Personal Email": "nil",
	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Social Media Member",
	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
		" Proficient in HTML, CSS, Tailwind CSS, and JavaScript. Skilled in front-end development and responsive web design.",
	"Instagram username": "https://www.instagram.com/vishwas_kumar_2003/",
	"LinkedIn Username":
		"https://www.linkedin.com/in/kunal-sinha-61236a259?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
	"Twitter Username":
		"https://x.com/devVishwas2003?t=tGg3G2Iah2t8N3JO752y_g&s=09",
	"Phone No.": 8009885083,
	"Profile Photo (preferably focused on face)":
		"visvas.jpg",
},
{
	"`": "8/2/2022 23:33:39",
	"Email Address": "24235@iiitu.ac.in",
	Name: "Jaishna S",
	"Roll No.": 21235,
	"Personal Email": "nil",
	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Social Media Member",
	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
		"Decent with c,c++,Java & python",
	"Instagram username": "https://www.instagram.com/.jaish_04.?igsh=d3diYjZyMDNwYW96",
	"LinkedIn Username":
		"https://www.linkedin.com/in/jaishna-s-01b51828b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
	"Twitter Username":
		"nil",
	"Phone No.":8500524442,
	"Profile Photo (preferably focused on face)":
		"Jaish.jpg",
},

{
	"`": "8/2/2022 23:33:39",
	"Email Address": "24235@iiitu.ac.in",
	Name: "Pradipta Kumar",
	"Roll No.": 21235,
	"Personal Email": "rishav7781@gmail.com",
	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Social Media Member",
	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
		"A web developer and springboot newbie. Learning everything.",
	"Instagram username": "pradiptakumar2002",
	"LinkedIn Username":
		"Pradipta Kumar jena ",
	"Twitter Username":
		"nil",
	"Phone No.": 9937260879,
	"Profile Photo (preferably focused on face)":
		"pradip.jpeg",
},
{
	"`": "8/2/2022 23:33:39",
	"Email Address": "24235@iiitu.ac.in",
	Name: "Rohith Lokesh",
	"Roll No.": 21235,
	"Personal Email": "nil",
	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Design Team Member",
	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
		"Html,Css,Java,Javascript,Python Poster and Brochure Designing Using Canva,FigmaBasic idea on Excel,SQL,Tableau,Data Visualisation",
	"Instagram username": "@rohith.lokesh.2912",
	"LinkedIn Username":
		"https://www.linkedin.com/in/rohith-lokesh-27581824b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
	"Twitter Username":
		"@rohithl2912",
	"Phone No.": 9632911306,
	"Profile Photo (preferably focused on face)":
		"Rohith.jpg",
},

	
	{
		"`": "8/2/2022 23:37:14",
		"Email Address": "20309@iiitu.ac.in",
		Name: "Joel Thomas",
		"Roll No.": 20309,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
			"Tech Team Member",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"A quick learner with a background in web-development",
		"Instagram username": "_jt.10_",
		"LinkedIn Username": "https://www.linkedin.com/in/joel-thomas-58a857218?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
		"Twitter Username": "Nil",
		"Phone No.":8310819628,
		"Profile Photo (preferably focused on face)":
			"Joel.jpg",
	},
	{
		"`": "8/2/2022 23:37:27",
		"Email Address": "20152@iiitu.ac.in",
		Name: "Ashish Ramesh",
		"Roll No.": 20152,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
			"Tech Team Member",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"I am an avid learner, always on the hunt for new experiences",
		"Instagram username": "nil",
		"LinkedIn Username": "nil",
		"Twitter Username": "nil",
		"Phone No.": "#",
		"Profile Photo (preferably focused on face)":
			"Ashish.jpg",
	},
	{
		"`": "8/2/2022 23:54:48",
		"Email Address": "20240@iiitu.ac.in",
		Name: "Kunal R",
		"Roll No.": 20240,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
			"Tech Team Member",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"Proficient in HTML, CSS, JavaScript, Tailwind, React.js basics, competitive coder, passionate about AI, aspiring full-stack web application developer.",
		"Instagram username": "https://www.instagram.com/29captaink?igsh=MWMyaXU0NnU2a2Jydw==",
		"LinkedIn Username": "https://www.linkedin.com/in/kunal-sinha-61236a259?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
		"Twitter Username": "https://twitter.com/kunalsinha472",
		"Phone No.": 6206788728,
		"Profile Photo (preferably focused on face)":
			"kunal.jpg",
	},
	{
		"`": "8/2/2022 23:58:55",
		"Email Address": "20146@iiitu.ac.in",
		Name: "Sahil Parashram",
		"Roll No.": 20146,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
			"Tech Team Member",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"I am very active Towards New Technology, a great Helper and Slightly Tensed.",
		"Instagram username": "Priyanshu_singh_1877",
		"LinkedIn Username": "Priyanshu Kumar Singh",
		"Twitter Username": "this-deepraj",
		"Phone No.": "09304011004",
		"Profile Photo (preferably focused on face)":
			"sahil.jpeg",
	},
	{
		"`": "8/2/2022 23:59:50",
		"Email Address": "20157@iiitu.ac.in",
		Name: "Heerath Bhat",
		"Roll No.": 20157,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
			"Tech Team Member",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"I'm a developer with a passion for Android development using Flutter and Kotlin, machine learning with TensorFlow, and crafting dynamic web experiences using React. ",
		"Instagram username": "nil",
		"LinkedIn Username":
			"https://www.linkedin.com/in/heerath-bhat-0a27841b2/",
		"Twitter Username": "https://twitter.com/HeerathBhat",
		"Phone No.": 9797243748,
		"Profile Photo (preferably focused on face)":
			"Heerat.jpg",
	},
	{
		"`": "8/3/2022 0:15:27",
		"Email Address": "19331@iiitu.ac.in",
		Name: "Prashant P Subedar",
		"Roll No.": 19331,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
			"President",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"Web Developer [Good working with React.js and Angular.js Framework]",
		"Instagram username": " prashant_subedar13",
		"LinkedIn Username": "https://www.linkedin.com/in/prashant-p-subedar-34abb71b7?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
		"Twitter Username": "nil",
		"Phone No.": "8106084090",
		"Profile Photo (preferably focused on face)":
			"Prashant.jpeg",
	},
	{
		"`": "8/3/2022 6:36:51",
		"Email Address": "21334@iiitu.ac.in",
		Name: "Shrish Agarwal",
		"Roll No.": 21334,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
			"Media Team Member",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"I have an expertise in DBMS, PL/SQL, ORACLE and Cyber Security ",
		"Instagram username": "https://www.instagram.com/agarwal.shrish?igsh=MXdvcjB5b3d5NndoYg==",
		"LinkedIn Username": "https://www.linkedin.com/in/shrish-agarwal-346496270?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
		"Twitter Username": "nil",
		"Phone No.": 9113778721,
		"Profile Photo (preferably focused on face)":
			"Shrish.jpg",
	},
	{
		"`": "8/3/2022 18:52:19",
		"Email Address": "21110@iiitu.ac.in",
		Name: "Kashish Pandey",
		"Roll No.": 21110,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
			"Media Team Member",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"I encompass skills in DBMS, PL/SQL, Oracle, and fundamental cybersecurity principles like encryption and network security.",
		"Instagram username": "https://www.instagram.com/kashishpandey__?igsh=MTMza292azdsYWJhbw%3D%3D&utm_source=qr",
		"LinkedIn Username": "http://linkedin.com/in/kashish-pandey-a042821a7",
		"Twitter Username": "nil",
		"Phone No.": 9471740040,
		"Profile Photo (preferably focused on face)":
			"kashish.jpg",
	},
	{
		"`": "8/3/2022 20:06:52",
		"Email Address": "21239@iiitu.ac.in",
		Name: "Nisaar Ahmad",
		"Roll No.": 21239,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Media Team Member",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"Hard working, passionate about learning new things and technology and having good expertise in Tech. always ready to learn new things.",
		"Instagram username": "(nisar__ahamed)https://www.instagram.com/nisar__ahamed?igsh=MW05cjFtZmNoZHd3Zg==",
		"LinkedIn Username": "https://www.linkedin.com/in/nisar-ahamed-aaaaa1214?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
		"Twitter Username": "nil",
		"Phone No.":9019164344 ,
		"Profile Photo (preferably focused on face)":
			"Nisar.jpg",
	},
	// {
	// 	"`": "8/3/2022 23:57:27",
	// 	"Email Address": "21229@iiitu.ac.in",
	// 	Name: "Ananya V",
	// 	"Roll No.": 21229,
	// 	"Personal Email": "prakhar2111flash@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "CP",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"Keen to learn new things, hard working, team player",
	// 	"Instagram username": "prakhar2111",
	// 	"LinkedIn Username":
	// 		"https://www.linkedin.com/in/prakhar-jaiswal-8a4662235/",
	// 	"Twitter Username": "none",
	// 	"Phone No.": 7905728886,
	// 	"Profile Photo (preferably focused on face)":
	// 		"Ana.jpeg",
	// },
	// {
	// 	"`": "8/4/2022 0:00:48",
	// 	"Email Address": "21329@iiitu.ac.in",
	// 	Name: "Shrish Agarwal",
	// 	"Roll No.": 21329,
	// 	"Personal Email": "sauravsable4102@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "CP",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"I am an enthusiastic Learner.",
	// 	"Instagram username": "_.stubborn._007",
	// 	"LinkedIn Username": "saurav sable",
	// 	"Twitter Username": "",
	// 	"Phone No.": 9823585926,
	// 	"Profile Photo (preferably focused on face)":
	// 		"Shrish.jpg",
	// },
	// {
	// 	"`": "8/4/2022 19:40:05",
	// 	"Email Address": "21328@iiitu.ac.in",
	// 	Name: "Kashish Pandey",
	// 	"Roll No.": 21328,
	// 	"Personal Email": "sarthaksingh661@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"Web Developer , CP",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"I am passionate about learning new skills.",
	// 	"Instagram username": "s4rthak12",
	// 	"LinkedIn Username": "Sarthak Singh",
	// 	"Twitter Username": "",
	// 	"Phone No.": 7248132013,
	// 	"Profile Photo (preferably focused on face)":
	// 		"kashish.jpg",
	// },
	// {
	// 	"`": "8/4/2022 0:02:48",
	// 	"Email Address": "21316@iiitu.ac.in",
	// 	Name: "Nisaar Ahmad",
	// 	"Roll No.": 21316,
	// 	"Personal Email": "mayanksharma88999@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "tP",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"A attentive listener with a curious mind",
	// 	"Instagram username": "may_nk88999",
	// 	"LinkedIn Username": "Mayank Sharma",
	// 	"Twitter Username": "",
	// 	"Phone No.": 8959336005,
	// 	"Profile Photo (preferably focused on face)":
	// 		"Nisar.jpg",
	// },
	// {
	// 	"`": "8/4/2022 0:09:03",
	// 	"Email Address": "21211@iiitu.ac.in",
	// 	Name: "Ashray Tiwari",
	// 	"Roll No.": 21211,
	// 	"Personal Email": "ashraytiwari17@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"Web developer, Competituve programmer",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"Eager to learn",
	// 	"Instagram username": "https://www.instagram.com/l.chiku.l/",
	// 	"LinkedIn Username":
	// 		"https://www.linkedin.com/in/ashray-tiwari-7562a9229",
	// 	"Twitter Username": "https://mobile.twitter.com/ashraytiwari17",
	// 	"Phone No.": 7440450750,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1uGuDrXtvnBQUo-R4qvKuZJLSbHs_Drbo",
	// },
	// {
	// 	"`": "8/4/2022 6:42:12",
	// 	"Email Address": "21251@iiitu.ac.in",
	// 	Name: "Vedant Prashant Dhumane",
	// 	"Roll No.": 21251,
	// 	"Personal Email": "vedantp.dhumane@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "CP",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"I am a critical thinker , who believes that impossible can be achieved with teamwork.",
	// 	"Instagram username": "none",
	// 	"LinkedIn Username": "Vedant Dhumane",
	// 	"Twitter Username": "none",
	// 	"Phone No.": 9767892796,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1zFLQdW2YFxVlsHGbOLE5Y8tJUEBliLYR",
	// },
	// {
	// 	"`": "8/4/2022 7:32:31",
	// 	"Email Address": "20217@iiitu.ac.in",
	// 	Name: "Krishak Chhura",
	// 	"Roll No.": 20217,
	// 	"Personal Email": "krishakchhura@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": 143001,
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"I never give up until I get something right.",
	// 	"Instagram username": "krishakchhura",
	// 	"LinkedIn Username": "Krishak Chhura",
	// 	"Twitter Username": "",
	// 	"Phone No.": 7009927379,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=12scZe7A1C3FxPnP6BRc9Qdy1nASSG9mE",
	// },
	// {
	// 	"`": "8/4/2022 8:52:30",
	// 	"Email Address": "21333@iiitu.ac.in",
	// 	Name: "Suraj Maheshwari",
	// 	"Roll No.": 21333,
	// 	"Personal Email": "surajmaheshwari.gn@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "CP",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"As a student, I'm under goals of acquiring knowledge and developing skills in the desired field.",
	// 	"Instagram username": "surajmaheshwari159",
	// 	"LinkedIn Username": "Suraj Maheshwari",
	// 	"Twitter Username": "",
	// 	"Phone No.": 9602787267,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1SdrD-hlXHXosqav3ZiJ-OTj5xk4gxxr8",
	// },
	// {
	// 	"`": "8/8/2022 22:37:07",
	// 	"Email Address": "20244@iiitu.ac.in",
	// 	Name: "Pratham Singh",
	// 	"Roll No.": 20244,
	// 	"Personal Email": "prathambsingh891@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"Web Developer",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"I am an avid learner, always on the hunt for new experiences.",
	// 	"Instagram username": "__pratham891",
	// 	"LinkedIn Username":
	// 		"https://www.linkedin.com/in/pratham-singh-596591203",
	// 	"Twitter Username": "SinghPratham891",
	// 	"Phone No.": 7600662459,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1gDPxzukc1ET45bCOlJZf-LdzzEP-kxz9",
	// },
	// {
	// 	"`": "8/4/2022 10:39:03",
	// 	"Email Address": "21327@iiitu.ac.in",
	// 	Name: "Saransh Baniyal",
	// 	"Roll No.": 21327,
	// 	"Personal Email": "saranshbaniyal14@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"CP and Android Developer(beginner)",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"Seeker | Tech Enthusiast | Melomaniac",
	// 	"Instagram username": "saransh_baniyal",
	// 	"LinkedIn Username": "www.linkedin.com/in/saransh-baniyal",
	// 	"Twitter Username": "https://twitter.com/SaranshBaniyal",
	// 	"Phone No.": 9760107102,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1e0u-hr2EilaEwW9jcNuZlspWgxQoc6pY",
	// },
	// {
	// 	"`": "8/4/2022 11:43:10",
	// 	"Email Address": "20115@iiitu.ac.in",
	// 	Name: "Anshul Kumar",
	// 	"Roll No.": 20115,
	// 	"Personal Email": "anshulkumar3552@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"Flutter Developer",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"Always ready for learn new things for my future improvement.",
	// 	"Instagram username": "anshulkumar2311",
	// 	"LinkedIn Username": "https://www.linkedin.com/in/anshulkumar2311",
	// 	"Twitter Username": "anshulkumar2311",
	// 	"Phone No.": "+919456862963",
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1bz6KMug5Q1nFlnfqF-ILWXl1cpRmciX4",
	// },
	// {
	// 	"`": "8/4/2022 12:22:53",
	// 	"Email Address": "20149@iiitu.ac.in",
	// 	Name: "Rishi Srivastava",
	// 	"Roll No.": 20149,
	// 	"Personal Email": "rishisr.9044@gmal.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"CP / WebDev",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"I am highly goal oriented and open to challenges.",
	// 	"Instagram username": "rishisr_9044",
	// 	"LinkedIn Username": "https://github.com/RishiSr",
	// 	"Twitter Username": "",
	// 	"Phone No.": 7985714918,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1VKHNglV6lrECW-IQ2TF92UQVU68rZwq1",
	// },
	// {
	// 	"`": "8/4/2022 13:09:12",
	// 	"Email Address": "21310@iiitu.ac.in",
	// 	Name: "Deepak",
	// 	"Roll No.": 21310,
	// 	"Personal Email": "deepakanonymous75@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "CP",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"I like new challenges.",
	// 	"Instagram username": "sharmadeepak0501",
	// 	"LinkedIn Username": "deepak-sharma-84994b234",
	// 	"Twitter Username": "",
	// 	"Phone No.": 9559127439,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1rDH7n8pvyyE_UQTeTF-nZvqgyx6BJae0",
	// },
	// {
	// 	"`": "8/4/2022 13:12:41",
	// 	"Email Address": "21311@iiitu.ac.in",
	// 	Name: "Gaurav Singh",
	// 	"Roll No.": 21311,
	// 	"Personal Email": "Gauravsingh9411222326@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "CP",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"Passionate Learner, hardwork never brothers me",
	// 	"Instagram username": "gaurav. Singh_04",
	// 	"LinkedIn Username": "gaurav-singh-8121b423b",
	// 	"Twitter Username": "",
	// 	"Phone No.": 6398330445,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1TCz1gY5XMH7TBcljOUpplAJIgt00Jmpi",
	// },
	// {
	// 	"`": "8/4/2022 14:43:40",
	// 	"Email Address": "21112@iiitu.ac.in",
	// 	Name: "Ayushi Garg",
	// 	"Roll No.": 21112,
	// 	"Personal Email": "gargayushi507@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "CP",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"I am not perfect but try to give my best.",
	// 	"Instagram username": "Ayu_shigarg08",
	// 	"LinkedIn Username": "Ayushi Garg",
	// 	"Twitter Username": "Ayushi",
	// 	"Phone No.": 7849995391,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1x2utgFiEfO0fRzcz7qJ-1i_bxS7qEeDG",
	// },
	// {
	// 	"`": "8/4/2022 14:02:22",
	// 	"Email Address": "21226@iiitu.ac.in",
	// 	Name: "Manas Sharma",
	// 	"Roll No.": 21226,
	// 	"Personal Email": "manassharma850@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"Volunteer",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"I want learn new things and want to enjoy it",
	// 	"Instagram username": "Manas0603",
	// 	"LinkedIn Username": "Manas Sharma",
	// 	"Twitter Username": "",
	// 	"Phone No.": 7627075413,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=16Zbxh68I_1yQIqv0Ii8DWm4k014TTpzk",
	// },
	// {
	// 	"`": "8/4/2022 14:11:24",
	// 	"Email Address": "21155@iiitu.ac.in",
	// 	Name: "UPHAR GAUR",
	// 	"Roll No.": 21155,
	// 	"Personal Email": "Uphargaur@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Cp",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"I am persistent and hardworking",
	// 	"Instagram username": "Ujjwal_sharma_u.j",
	// 	"LinkedIn Username": "Uphar gaur",
	// 	"Twitter Username":
	// 		"https://twitter.com/Ujjwal85288906?t=RF5fgIx6X_1_kmtBQJNoxw&s=09",
	// 	"Phone No.": 7988204539,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1KiR9fV72zEfKnCq5Nwntzjvf2Vq5timT",
	// },
	// {
	// 	"`": "8/4/2022 16:17:31",
	// 	"Email Address": "21318@iiitu.ac.in",
	// 	Name: "Mitesh Singla",
	// 	"Roll No.": 21318,
	// 	"Personal Email": "miteshhsingla@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"Ui/Ux Designer",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"I am an ardent explorer.",
	// 	"Instagram username": "miteshh_singla",
	// 	"LinkedIn Username": "Mitesh Singla",
	// 	"Twitter Username": "",
	// 	"Phone No.": 8968146758,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1tGqNdipZaLQk0uWOwq4arMHpPu-Gm1Hj",
	// },
	// {
	// 	"`": "8/5/2022 9:19:20",
	// 	"Email Address": "20128@iiitu.ac.in",
	// 	Name: "Jalaj Pathak",
	// 	"Roll No.": 20128,
	// 	"Personal Email": "jalajpathak604@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"Web Developer",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"I am capable of changing my field as per requirement",
	// 	"Instagram username": "chill_kingg",
	// 	"LinkedIn Username": "Jalaj Pathak",
	// 	"Twitter Username": "",
	// 	"Phone No.": 9058831334,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1d1nKjhgUIp3rR9hOxO4aPWppbVxednQg",
	// },
	// {
	// 	"`": "8/5/2022 9:40:32",
	// 	"Email Address": "20158@iiitu.ac.in",
	// 	Name: "Shubhi Arora",
	// 	"Roll No.": 20158,
	// 	"Personal Email": "arora.shubhi0565@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"Upcoming SWE Intern'23 @ Microsoft, Competitive Programmer, Full stack developer.",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"I am a passionate person always open to learn new things.",
	// 	"Instagram username": "shubhi_arora1",
	// 	"LinkedIn Username": "Shubhi Arora",
	// 	"Twitter Username": "",
	// 	"Phone No.": 7668191238,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1FMdiP7qA-89sZ_INAQtej085Nec3KiMX",
	// },
	// {
	// 	"`": "8/5/2022 9:26:55",
	// 	"Email Address": "21128@iiitu.ac.in",
	// 	Name: "Lakshay arora",
	// 	"Roll No.": 21128,
	// 	"Personal Email": "lakshayarora0612@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"Video editor , cp",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"Quick learner and wants to grow more",
	// 	"Instagram username": "lakshay._.03",
	// 	"LinkedIn Username":
	// 		"https://www.linkedin.com/in/lakshay-arora-360b50228",
	// 	"Twitter Username": "",
	// 	"Phone No.": 9350073879,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1lx3nZaxTY-j-1Qxd9L5UPA_ri2BhhOlh",
	// },
	// {
	// 	"`": "8/5/2022 9:27:44",
	// 	"Email Address": "20106@iiitu.ac.in",
	// 	Name: "Akshat Khosya",
	// 	"Roll No.": 20106,
	// 	"Personal Email": "akshatdps12@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"Web Developer",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"Walking on water and developing software from a specification are easy if both are frozen",
	// 	"Instagram username": "akshat_khosya",
	// 	"LinkedIn Username": "akshat-khosya-7b9321201",
	// 	"Twitter Username": "",
	// 	"Phone No.": 9416908474,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1x6nXUNr2n4buXSh_zdpOXfpgW6s0h_h8",
	// },
	// {
	// 	"`": "8/5/2022 9:28:12",
	// 	"Email Address": "21135@iiitu.ac.in",
	// 	Name: "Piyush Singh",
	// 	"Roll No.": 21135,
	// 	"Personal Email": "ps671248@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"Web Developer , CP",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"I am a curious learner, always ready to explore new things.",
	// 	"Instagram username":
	// 		"https://instagram.com/_piyush_8_3_3?igshid=YmMyMTA2M2Y=",
	// 	"LinkedIn Username":
	// 		"https://www.linkedin.com/in/piyush-singh-403089221",
	// 	"Twitter Username": "",
	// 	"Phone No.": "+917310094880",
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1CbNt6XR3QJIiS2SCS-ey1UcozrVU-mIQ",
	// },
	// {
	// 	"`": "8/5/2022 9:44:44",
	// 	"Email Address": "20138@iiitu.ac.in",
	// 	Name: "Mitali Jain",
	// 	"Roll No.": 20138,
	// 	"Personal Email": "itzmitali03@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"Former SDE Intern @Flipkart, MERN Stack Developer",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"Always in hunt to grab experience and knowledge.",
	// 	"Instagram username": "mitali_jain_3",
	// 	"LinkedIn Username": "mitali-jain-6b4b1a204",
	// 	"Twitter Username": "",
	// 	"Phone No.": 7737149963,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1SAYcCmsz4OslVzWaM-vdEYf-X9u0wgmB",
	// },
	// {
	// 	"`": "8/5/2022 9:47:06",
	// 	"Email Address": "21301@iiitu.ac.in",
	// 	Name: "Abhay Pratap Singh",
	// 	"Roll No.": 21301,
	// 	"Personal Email": "singhpratapabhay60@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"Content team",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"I'm supposed to be a soldier who never blows his composure and can do whatever is needed to win.",
	// 	"Instagram username": "soapabhay23",
	// 	"LinkedIn Username": "Abhay Pratap Singh",
	// 	"Twitter Username": "Nil",
	// 	"Phone No.": 8218275527,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1sAMlXpCVk3IvnujzVwiwRao-M2fUH6M6",
	// },
	// {
	// 	"`": "8/5/2022 12:30:03",
	// 	"Email Address": "19315@iiitu.ac.in",
	// 	Name: "Narayana S",
	// 	"Roll No.": 19315,
	// 	"Personal Email": "johari.kushagra13@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"Vice-President",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"Avid learner who always on the hunt for new experience",
	// 	"Instagram username": "kushagra_johari",
	// 	"LinkedIn Username": "Kushagra Johari",
	// 	"Twitter Username": "",
	// 	"Phone No.": 7668987397,
	// 	"Profile Photo (preferably focused on face)":
	// 		"Nara.jpg",
	// },
	// {
	// 	"`": "8/5/2022 16:53:19",
	// 	"Email Address": "20235@iiitu.ac.in",
	// 	Name: "Ram Ratan Singh",
	// 	"Roll No.": 20235,
	// 	"Personal Email": "ramratan72908@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"CP, Web Developer",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"I am a competative programmer and like to solve real life problems.",
	// 	"Instagram username": "azog_the_defiler",
	// 	"LinkedIn Username": "Ram Ratan singh",
	// 	"Twitter Username": "",
	// 	"Phone No.": 7290826107,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1cbl1a6BjslRvLLhq-CFocVAxqrrdQR_2",
	// },
	// {
	// 	"`": "8/5/2022 20:18:16",
	// 	"Email Address": "20163@iiitu.ac.in",
	// 	Name: "Varun Prohit",
	// 	"Roll No.": 20163,
	// 	"Personal Email": "varunprohit222@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"CP, Machine Learning",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"I am a competitive programmer, interested to explore new depth of artificial intelligence.",
	// 	"Instagram username": "varunprohit",
	// 	"LinkedIn Username": "varunprohit",
	// 	"Twitter Username": "prohitvarun",
	// 	"Phone No.": "08103325733",
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1OanGlJyV2NhTAbUMaUciWGunY6l32ufE",
	// },
	// {
	// 	"`": "8/5/2022 21:09:04",
	// 	"Email Address": "21240@iiitu.ac.in",
	// 	Name: "Sejal jain",
	// 	"Roll No.": 21240,
	// 	"Personal Email": "sejaljain897@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "CP",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"I am always energetic and eager to earn new skills.",
	// 	"Instagram username": "Sejaljain1874",
	// 	"LinkedIn Username": "Sejal jain",
	// 	"Twitter Username": "Nope",
	// 	"Phone No.": 8505025697,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1sNjzlOJZxBCgAh3af7bvvqOT0uKb5MiK",
	// },
	{
		"`": "8/5/2022 22:05:09",
		"Email Address": "19307@iiitu.ac.in",
		Name: "Narayanan S",
		"Roll No.": 19307,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
			"Vice President",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"Graphic Designer specialized in softwares like Figma and Canva, Python Enthusiast",
		"Instagram username": "https://www.instagram.com/",
		"LinkedIn Username": "https://www.linkedin.com/in/narayanan-sreeraman-39662a1a0?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
		"Twitter Username": "nil",
		"Phone No.": "9986719979 ",
		"Profile Photo (preferably focused on face)":
			"Nara.jpg",
	},
	// {
	// 	"`": "8/8/2022 19:40:35",
	// 	"Email Address": "19308@iiitu.ac.in",
	// 	Name: "Ullas",
	// 	"Roll No.": 19308,
	// 	"Personal Email": "this.devang@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)": "Media Lead",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"Curious for innovation",
	// 	"Instagram username": "_devang_sharma_",
	// 	"LinkedIn Username": "-devang-sharma-",
	// 	"Twitter Username": "",
	// 	"Phone No.": 7877857818,
	// 	"Profile Photo (preferably focused on face)":
	// 		"Ullas.jpeg",
	// },
	// {
	// 	"`": "8/8/2022 19:58:21",
	// 	"Email Address": "19121@iiitu.ac.in",
	// 	Name: "Abhinav Majeti",
	// 	"Roll No.": 19121,
	// 	"Personal Email": "kavishagupta20340@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"Design Lead",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"I'm an avid learner, always on the hunt for new experiences",
	// 	"Instagram username": "_mozza_fiato_",
	// 	"LinkedIn Username": "Kavisha Gupta",
	// 	"Twitter Username": "",
	// 	"Phone No.": 9628820340,
	// 	"Profile Photo (preferably focused on face)":
	// 		"Maja.jpeg",
	// },
	{
		"`": "8/8/2022 20:04:36",
		"Email Address": "19137@iiitu.ac.in",
		Name: "Hakeem Hameem",
		"Roll No.": 19137,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
			"Tech Lead",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"Wake up to reality! Nothing ever goes as planned in this world. The longer you live, the more you realize that in this reality, only pain, suffering, and futility exist",
		"Instagram username": "_hameemhussain",
		"LinkedIn Username": "www.linkedin.com/in/hameem1",
		"Twitter Username": "nil",
		"Phone No.": "7006385519",
		"Profile Photo (preferably focused on face)":
			"Hameem.jpeg",
	},
	{
		"`": "8/8/2022 22:50:06",
		"Email Address": "19155@iiitu.ac.in",
		Name: "Ullas",
		"Roll No.": 19155,
		"Personal Email": "pvanshika217@gmail.com",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
			"Media Lead",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"ML & DL buff learning to build various AI models using ANN & CNN handy with a camera",
		"Instagram username": "im_ullas",
		"LinkedIn Username": "Ullas mohan",
		"Twitter Username": "Im_ullas",
		"Phone No.": "9900531583",
		"Profile Photo (preferably focused on face)":
			"Ullas.jpeg",
	},
	{
		"`": "8/8/2022 22:50:06",
		"Email Address": "19155@iiitu.ac.in",
		Name: "Abhinav Majeti",
		"Roll No.": 19155,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
			"Design Lead",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"I am a tech enthusiast who loves to learn an implement new technologies, solve problems and build solutions that contribute to a better future.",
		"Instagram username": "nil",
		"LinkedIn Username": "nil",
		"Twitter Username": "nil",
		"Phone No.": "+#",
		"Profile Photo (preferably focused on face)":
			"Maja.jpeg",
	},
	{
		"`": "8/8/2022 22:50:06",
		"Email Address": "19155@iiitu.ac.in",
		Name: "Rounak Pande",
		"Roll No.": 19155,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
			"Social Media Lead",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"Social Media Influencer!",
		"Instagram username": "rounnakk.p",
		"LinkedIn Username": "https://www.linkedin.com/in/rounak-pande-3a3739223?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
		"Twitter Username": "rounakpande1",
		"Phone No.": "9067794457",
		"Profile Photo (preferably focused on face)":
			"pande.jpeg",
	},






	{
		"`": "8/8/2022 22:50:06",
		"Email Address": "19155@iiitu.ac.in",
		Name: "Kathrine John Kennedy",
		"Roll No.": 19155,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
			"Content Lead",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"Enthusiastic about Cryptography Machine Learning. Database management is where I find solace. Been to Airbus, NetApp, Caterpillar, SAP as a public speaker. A holistic learner, takes up new challenges.",
		"Instagram username": "nil",
		"LinkedIn Username": "https://www.linkedin.com/in/katharine-kennedy-687981267/",
		"Twitter Username": "nil",
		"Phone No.": "+#",
		"Profile Photo (preferably focused on face)":
			"kk.jpg",
	},
	{
		"`": "8/8/2022 22:50:06",
		"Email Address": "19155@",
		Name: "Koushik Ram",
		"Roll No.": 19155,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
			"Event Cordinator ",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"Full Stack Developer | Web Design Enthusiast | Tech Innovator | ISE Undergrad @MVJCE",
		"Instagram username": "https://www.instagram.com/jr.ram7",
		"LinkedIn Username": "https://www.linkedin.com/in/itskoushikram/",
		"Twitter Username": "https://x.com/koushik_ram18?t=FaPTrw6yrtZe-7hPConXjQ&s=09",
		"Phone No.": "9886039692",
		"Profile Photo (preferably focused on face)":
			"kushik.jpeg",
	},
	{
		"`": "8/8/2022 22:50:06",
		"Email Address": "19155@",
		Name: "Heerat Bhat",
		"Roll No.": 19155,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
			"Moderator",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"I'm a developer with a passion for Android development using Flutter and Kotlin, machine learning with TensorFlow, and crafting dynamic web experiences using React. ",
		"Instagram username": "nil",
		"LinkedIn Username": "https://www.linkedin.com/in/heerath-bhat-0a27841b2/",
		"Twitter Username": "https://twitter.com/HeerathBhat",
		"Phone No.": "9797243748",
		"Profile Photo (preferably focused on face)":
			"Heerat.jpg",
	},
	{
		"`": "8/8/2022 22:50:06",
		"Email Address": "19155@",
		Name: "Greeshma DJ",
		"Roll No.": 19155,
		"Personal Email": "nil",
		"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
			"Moderator",
		"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
			"Experienced programmer proficient in HTML, SQL, C++, Java, and C. Completed 3 internships, contributed to hackathon projects (smart electricity, trolley, ambulance).",
		"Instagram username": "https://instagram.com/greeshma_dj?igshid=YzAwZjE1ZTI0Zg==",
		"LinkedIn Username": "https://www.linkedin.com/in/greeshma-dj-5b6a06242?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
		"Twitter Username": "nil",
		"Phone No.": "7483494661",
		"Profile Photo (preferably focused on face)":
			"Greeshma.jpeg",
	},
	// {
	// 	"`": "8/8/2022 20:34:39",
	// 	"Email Address": "20105@iiitu.ac.in",
	// 	Name: "Akminderjeet Singh",
	// 	"Roll No.": 20105,
	// 	"Personal Email": "jeetakminder@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"Web Developer",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"Work smart , not definitely Hard.",
	// 	"Instagram username": "ranadhaliwal___",
	// 	"LinkedIn Username": "Akmindejeet Singh",
	// 	"Twitter Username": "",
	// 	"Phone No.": 7087197427,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=19zMNGgswMFlB2Bo-CFiYn2Is5E5Vs3Lp",
	// },
	// {
	// 	"`": "8/9/2022 21:22:18",
	// 	"Email Address": "20325@iiitu.ac.in",
	// 	Name: "Pankaj",
	// 	"Roll No.": 20325,
	// 	"Personal Email": "pankajsinghrana2003@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"Web Developer",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"I am web developer and always try to solve new problems.",
	// 	"Instagram username": "nmastepankaj",
	// 	"LinkedIn Username": "nmastepankaj",
	// 	"Twitter Username": "nmastepankaj",
	// 	"Phone No.": 8285930412,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1AkT4yzxA_GF7ggV7kHOV0ZL0UagUbEe-",
	// },
	// {
	// 	"`": "8/18/2022 15:38:37",
	// 	"Email Address": "20155@iiitu.ac.in",
	// 	Name: "Sayak Mondal",
	// 	"Roll No.": 20155,
	// 	"Personal Email": "sayakmondal2001@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"App developer, CP",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"Whenever you don't win make sure you learn...",
	// 	"Instagram username": "ig_d3monic",
	// 	"LinkedIn Username": "sayak-mondal-b70384200",
	// 	"Twitter Username": "ig_d3monic",
	// 	"Phone No.": 8373082231,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1yXKiFnWKF3rDR3iCsxis_L1dl_E_ZMoD",
	// },
	// {
	// 	"`": "8/18/2022 15:50:58",
	// 	"Email Address": "20318@iiitu.ac.in",
	// 	Name: "Divyansh Jindal",
	// 	"Roll No.": 20318,
	// 	"Personal Email": "divyanshjindal18@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"Flutter Developer",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"I am an avid learner",
	// 	"Instagram username": "_divyansh.jindal_",
	// 	"LinkedIn Username":
	// 		"https://www.linkedin.com/in/divyansh-jindal-449641201/",
	// 	"Twitter Username": "",
	// 	"Phone No.": 9041574035,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1J7a7OUNmpIH0ROvOFhRQHZkNX2S2Yf_Y",
	// },
	// {
	// 	"`": "8/18/2022 19:23:25",
	// 	"Email Address": "20225@iiitu.ac.in",
	// 	Name: "Nikhil Bhatia",
	// 	"Roll No.": 20225,
	// 	"Personal Email": "nickbhatia2002@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"Full stack Web Developer",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"I would describe myself as innovative, creative, adaptable to change, a fast learner, and someone who is prepared to go above and beyond what is required as a bear minimum",
	// 	"Instagram username": "bhatia_ji99",
	// 	"LinkedIn Username": "Nikhil Bhatia",
	// 	"Twitter Username": "NikhilB63329083",
	// 	"Phone No.": 8440017893,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1uown-mXI-bWWjLIx4kmEEScGqP8B4rPA",
	// },
	// {
	// 	"`": "8/18/2022 19:51:50",
	// 	"Email Address": "20236@iiitu.ac.in",
	// 	Name: "Rohit Kumar Gupta",
	// 	"Roll No.": 20236,
	// 	"Personal Email": "anishgupta8004@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"Web Developer and CP",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"Tech Enthusiast",
	// 	"Instagram username": "rohitrocks1000",
	// 	"LinkedIn Username": "Rohit kumar Gupta",
	// 	"Twitter Username": "",
	// 	"Phone No.": 9305737479,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1iny9QDV5CpX9BTAAYtK5QxavVwKOQmde",
	// },
	// {
	// 	"`": "8/24/2022 14:22:06",
	// 	"Email Address": "20162@iiitu.ac.in",
	// 	Name: "Vansh Singh",
	// 	"Roll No.": 20162,
	// 	"Personal Email": "vanshsingh1617@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"SDE, Full Stack Web Developer, UI/UX Designer",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"na",
	// 	"Instagram username": "https://www.instagram.com/va1nsh_?r=nametag",
	// 	"LinkedIn Username": "https://www.linkedin.com/in/vansh-singh-0414",
	// 	"Twitter Username":
	// 		"https://twitter.com/V_a1nsh14?t=tk7f5TzeTf1QKGEui6sf6Q&s=09",
	// 	"Phone No.": 9569649636,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1Y7hjgUMF_tgWh-f-jveOP9uV6peM7Fd0",
	// },
	// {
	// 	"`": "8/24/2022 17:34:11",
	// 	"Email Address": "20220@iiitu.ac.in",
	// 	Name: "Mohit Bagdi",
	// 	"Roll No.": 20220,
	// 	"Personal Email": "mohitbagdi280@gmail.com",
	// 	"Designation(E.g:- Web Developer, Graphic Designer, CP, etc)":
	// 		"Graphic Designer",
	// 	"A one liner to describe yourself.(E.g:- I am an avid learner, always on the hunt for new experiences)":
	// 		"Some time I roar",
	// 	"Instagram username": "edito0",
	// 	"LinkedIn Username":
	// 		"(Mohit bagdi)https://www.linkedin.com/in/mohit-bagdi-356463240",
	// 	"Twitter Username": "",
	// 	"Phone No.": 9351731524,
	// 	"Profile Photo (preferably focused on face)":
	// 		"https://drive.google.com/open?id=1b9rjhJlBQPXGEv4a3zQ_Be1l8fadyIIW",
	// },
];

export default allPeople;
