
import l1 from "../images/galay/l1.jpg";
import l2 from "../images/galay/l2.jpg";
import l3 from "../images/galay/l3.jpg";
import l4 from "../images/galay/l4.jpg";
import l5 from "../images/galay/l5.jpg";
import l6 from "../images/galay/l6.jpg";
import l7 from "../images/galay/l7.jpg";
import l8 from "../images/galay/l8.jpg";
import l9 from "../images/galay/l9.jpg";
import l10 from "../images/galay/l10.jpg";
import l11 from "../images/galay/l11.jpg";
import l12 from "../images/galay/l12.jpg";
import l13 from "../images/galay/l13.jpg";
import l14 from "../images/galay/l14.jpg";
import l15 from "../images/galay/l15.jpg";
import l16 from "../images/galay/l16.JPG";
import l17 from "../images/galay/l17.JPG";
import l18 from "../images/galay/l18.JPG";
import l19 from "../images/galay/l19.JPG";
import l20 from "../images/galay/l20.JPG";
import l21 from "../images/galay/l21.JPG";
import l22 from "../images/galay/l22.JPG";
import l23 from "../images/galay/l23.JPG";
import l24 from "../images/galay/l24.JPG";
import l25 from "../images/galay/l25.JPG";
import l26 from "../images/galay/l26.JPG";
import l27 from "../images/galay/l27.JPG";
import l29 from "../images/galay/l29.JPG";
import l30 from "../images/galay/l30.JPG";


const images = [
	l30,
	l1,
	l2,
	l3,
	l4,
	l5,
	l6,
	l7,
	l8,
	l9,
	l10,
	l11,
	l12,
	l13,
	l14,
	l15,
	l16,
	l17,
	l18,
	l19,
	l20,
	l21,
	l22,
	l23,
	l24,
	l25,
	l26,
	l27,
	l29,
	
];

export default images;
