import sih from "../images/sih.png";
import acm from "../images/acm.png";
import gsoc from "../images/gsoc.png";

const achievements = [
	{

		
		// title: "No Upcoming events for Now.... Stay Tuned",
		// image: sih,
		// participants: [
		// 	"",""
		// ],
	},
	// {
	// 	title: "Smart India Hackathon",
	// 	image: sih,
	// 	participants: [
	// 		"",""
	// 	],
	// },
	// {
	// 	title: "ACM-ICPC",
	// 	image: acm,
	// 	participants: [""],
	// },
	// {
	// 	title: "ACM-ICPC",
	// 	image: acm,
	// 	participants: [""],
	// },
	// {
	// 	title: "GSOC",
	// 	image: gsoc,
	// 	participants: [""],
	// },
];

export default achievements;
